import React from "react";
import { Login, LoginForm } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const styles = {
  main: {
    background: "url(https://res.cloudinary.com/zharkan/image/upload/c_scale,f_auto,q_auto,w_1488/v1587784633/BP/bvluype7k7q6kdfmipby.jpg)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    color: "#fff!important",
  },
  MuiInput: {
      input: {
          color: "red"
      }
  },
  MuiInputBase: {
      input: {
          color: "red",
      },
    root: {
      color: "#fff",
    },
  },
  card: {
    background: "#b79c7f",
  },
  input: {
    marginTop: "10em",
  },
  avatar: {
    background:
      "url(/logo.svg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    height: 120,
    backgroundColor: "#b79c7f",
  },
  icon: { display: "none" },
};

const CustomLoginForm = withStyles({
  button: { background: "#9baebc" },
})(LoginForm);

const CustomLoginPage = (props) => {
    const { trackPageView } = useMatomo();
    React.useEffect(() => {
      trackPageView();
    });
  return <Login loginForm={<CustomLoginForm />} {...props} />;
};

export default withStyles(styles)(CustomLoginPage);
