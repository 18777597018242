import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  DateField,
  CreateButton,
  useListContext,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import { DateInput } from "@semapps/date-components";
import BackButton from "../backButton";
import Calendar from "./calendar";

const resas = () => `/noBookings`; 

const Empty = () => {
  const { basePath } = useListContext();
  return (
    <Box
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      height="100%"
      display="flex"
      p={2}
    >
      <Box flexDirection="column">
        <Calendar />
        <Typography variant="h4">
          Aucune journée n'a été bloquée pour le moment.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
          En ajouter une ?
        </Typography>
        <CreateButton
          variant="contained"
          color="primary"
          basePath={basePath}
          style={{ marginTop: "20px" }}
        />
      </Box>
    </Box>
  );
};

const MenuToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton
      variant="outlined"
      color="secondary"
      style={{ marginLeft: "1rem" }}
    >
      Annuler
    </BackButton>
    <DeleteButton />
  </Toolbar>
);

export const NobookingsList = (props) => (
  <>
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={8}>
        <List
          {...props}
          exporter={false}
          empty={<Empty />}
          sort={{ field: "date_resa", order: "ASC" }}
        >
          <Datagrid rowClick="edit">
            <DateField label="Date" locales={"fr-FR"} source="date_resa" />
            <DeleteButton />
          </Datagrid>
        </List>
      </Grid>
    </Grid>
  </>
);

export const NobookingsCreate = (props) => (
  <>
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={3}>
        <Create title="Ajouter" {...props}>
          <SimpleForm toolbar={<MenuToolbar />} redirect={resas}>
            <span>Choisir une date :</span>
            <DateInput
              fullWidth={true}
              source="date_resa"
              label="Date"
              style={{ marginTop: "35px" }}
              options={{ format: "dd/MM/yyyy", ampm: false, clearable: true }}
              providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
          </SimpleForm>
        </Create>
      </Grid>
    </Grid>
  </>
);

export const NobookingsEdit = (props) => (
  <>
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={3}>
        <Edit title="Modifier" {...props}>
          <SimpleForm toolbar={<MenuToolbar />}>
            <span>Choisir une date :</span>
            <DateInput
              fullWidth={true}
              source="date_resa"
              label="Date"
              style={{ marginTop: "35px" }}
              options={{ format: "dd/MM/yyyy", ampm: false, clearable: true }}
              providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  </>
);
