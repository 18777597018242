import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  Filter,
  BooleanInput,
  regex,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import BackButton from "../backButton";
const validatePrix = regex(
  /^[0-9 ,]*$/,
  "Seulement chiffres et virgule, le symbole € est automatique rajouté"
);

const sides = () => `/sides`;

const MenuToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton
      variant="outlined"
      color="secondary"
      style={{ marginLeft: "1rem" }}
    >
      Annuler
    </BackButton>
  </Toolbar>
);

const WantedInfo = (props) => (
  <Filter {...props}>
    <BooleanInput
      resettable
      label="Gite"
      style={{ marginBottom: "25px", marginTop: "50px" }}
      source="gite"
    />
  </Filter>
);

export const SidesList = (props) => {
  return (
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <List {...props} exporter={false} bulkActionButtons={false} pagination={false} perPage={10}>
          <Datagrid rowClick="edit">
            <TextField label="Cliquer pour éditer" source="content_fr" />
          </Datagrid>
        </List>
      </Grid>
    </Grid>
  );
};


export const SidesEdit = (props) => {
  return (
    <Grid container justifyContent="center" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <Edit title="Modifier" {...props}>
          <SimpleForm toolbar={<MenuToolbar />}>
            <TextInput
              fullWidth={true}
              resettable
              multiline
              label="Intitulé FR"
              source="content_fr"
            />
            <TextInput
              fullWidth={true}
              resettable
              multiline
              label="Intitulé EN"
              source="content_en"
            />
         
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  );
};
