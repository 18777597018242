import * as React from "react";
import { DashboardMenuItem, MenuItemLink } from "react-admin";
import ViewHeadlineOutlinedIcon from "@material-ui/icons/ViewHeadlineOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import TodayIcon from "@material-ui/icons/Today";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import BlockIcon from "@material-ui/icons/Block";
import TreeMenu from "@bb-tech/ra-treemenu";
import { MenuItem, Typography, Box } from "@material-ui/core";
import { MenuList } from "@material-ui/core";
export const Menu = (props) => (
  <div>
    {" "}
    <Box alignContent="center" display="flex" flexDirection="column">
      <DashboardMenuItem
        leftIcon={<DashboardOutlinedIcon />}
        onClick={props.onMenuClick}
      />
      <MenuItemLink
        to="/suggestions/1"
        primaryText="Formule déjeuner"
        leftIcon={<RestaurantIcon />}
        onClick={props.onMenuClick}
      />
      <MenuItemLink
        to="/reservations"
        primaryText="Réservations"
        leftIcon={<TodayIcon />}
        onClick={props.onMenuClick}
      />
      <MenuItemLink
        to="/blockedlunchs"
        primaryText="Midi bloqué"
        leftIcon={<BlockIcon />}
        onClick={props.onMenuClick}
      />
      <MenuItemLink
        to="/blockeddiners"
        primaryText="Soir bloqué"
        leftIcon={<BlockIcon />}
        onClick={props.onMenuClick}
      />
      <MenuItemLink
        to="/nobookings"
        primaryText="Journée bloquée"
        leftIcon={<BlockIcon />}
        onClick={props.onMenuClick}
      />
      <MenuList>
        <TreeMenu />
      </MenuList>
    </Box>
    {/*   <MenuItemLink
      to="/chermettes"
      primaryText="Inventaire"
      leftIcon={<ViewHeadlineOutlinedIcon />}
      onClick={props.onMenuClick}
    /> */}
  </div>
);
