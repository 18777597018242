import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  Filter,
  regex,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import BackButton from "../backButton";
const validatePrix = regex(
  /^[0-9 ,]*$/,
  "Seulement chiffres et virgule, le symbole € est automatique rajouté"
);

const bottles = () => `/bottles`;

const MenuToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton
      variant="outlined"
      color="secondary"
      style={{ marginLeft: "1rem" }}
    >
      Annuler
    </BackButton>
    <DeleteButton />
  </Toolbar>
);

const WantedInfo = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Genre du vin"
      source="bottleGenre"
      reference="bottletypes"
    >
      <SelectInput optionText="typeOfBottle" />
    </ReferenceInput>
  </Filter>
);

export const BottlesList = (props) => {
  return (
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          perPage={10}
          filters={<WantedInfo />}
        >
          <Datagrid rowClick="edit">
            <TextField label="Nom du vin" source="title" />
            <TextField label="Genre" source="bottleGenre.typeOfBottle" />
          </Datagrid>
        </List>
      </Grid>
    </Grid>
  );
};

export const BottlesCreate = (props) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={5}>
          <Create title="Ajouter" {...props}>
            <SimpleForm toolbar={<MenuToolbar />} redirect={bottles}>
              <TextInput
                fullWidth
                resettable
                multiline
                label="Nom du vin"
                source="title"
              />
              <TextInput
                fullWidth
                multiline
                label="Description"
                source="description"
              />
              <TextInput label="Millésime" source="year" />
              <TextInput label="Prix" source="price" />
              <ReferenceInput
                label="Genre du vin"
                source="bottleGenre"
                reference="bottletypes"
              >
                <SelectInput optionText="typeOfBottle" />
              </ReferenceInput>
            </SimpleForm>
          </Create>
        </Grid>
      </Grid>
    </>
  );
};

export const BottlesEdit = (props) => {
  return (
    <Grid container justifyContent="center" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <Edit title="Modifier" {...props}>
          <SimpleForm toolbar={<MenuToolbar />}>
            <TextInput
              fullWidth
              resettable
              multiline
              label="Nom du vin"
              source="title"
            />
            <TextInput
              fullWidth
              multiline
              label="Description"
              source="description"
            />
            <TextInput label="Millésime" source="year" />
            <TextInput label="Prix" source="price" />
            <ReferenceInput
              label="Genre du vin"
              source="bottleGenre"
              reference="bottletypes"
            >
              <SelectInput optionText="typeOfBottle" />
            </ReferenceInput>
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  );
};
