import { Layout } from "react-admin"
import TreeMenu from '@bb-tech/ra-treemenu';
import { MenuItemLink } from "ra-ui-materialui";
import { Menu } from './Menu';
 
const CustomLayout = (props) => {
    TreeMenu.defaultProps.dashboardlabel = 'Tableau de Bord';
    
    return <Layout {...props} menu={Menu} />
};

export default CustomLayout;