import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  EditButton,
  Edit,
  Toolbar,
  SaveButton,
  regex,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import BackButton from "../backButton";

const suggestions = () => `/suggestions/1`;

const SuggestionstToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const validatePrix = regex(/^[0-9 ,]*$/, "Seulement chiffres et virgule");

export const TodayList = (props) => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  });
  return (
    <>
      <Grid
        container
        justify="space-around"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={5}>
          <List
            {...props}
            title="Suggestions du jour"
            bulkActionButtons={false}
            pagination={false}
            exporter={false}
            sort={{ field: "id", order: "DESC" }}
          >
            <Datagrid>
              <td>
                {" "}
                Editer les suggestions du jour
                <TextField />
              </td>
              <EditButton />
            </Datagrid>
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export const TodayEdit = (props) => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  });
  return (
    <>
      <Grid
        container
        justify="space-around"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={8}>
          <Edit title="Modifier" {...props} undoable={false}>
            <SimpleForm
              toolbar={<SuggestionstToolbar />}
              redirect={suggestions}
            >
              <TextInput
                fullWidth={true}
                resettable
                multiline
                label="Entrée du jour"
                source="suggestionsStarter"
              />
              <TextInput
                fullWidth={true}
                resettable
                multiline
                label="Plat du jour"
                source="suggestionsMain"
              />
              <TextInput
                fullWidth={true}
                resettable
                multiline
                label="Dessert du jour"
                source="suggestionsDessert"
              />
              <TextInput 
              resettable
              label="Prix de la formule"
              source="menuPrice"
              />
            </SimpleForm>
          </Edit>
        </Grid>
      </Grid>
    </>
  );
};
