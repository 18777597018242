import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  NumberInput,
  Filter,
  DateField,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import { DateInput } from "@semapps/date-components";
import BackButton from "../backButton";
import { Rings } from "svg-loaders-react";

const resas = () => `/reservations`;

const MenuToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton
      variant="outlined"
      color="secondary"
      style={{ marginLeft: "1rem" }}
    >
      Annuler
    </BackButton>
    <DeleteButton />
  </Toolbar>
);

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);
const WantedDate = (props) => (
  <Filter {...props}>
    <DateInput
      defaultValue={date}
      style={{ marginTop: "50px" }}
      source="dateResa"
      label="Rechercher par date"
      options={{ format: "dd/MM/yyyy", ampm: false, clearable: true }}
      providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
    />
    <NumberInput
      resettable
      label="ID Résa"
      style={{ marginBottom: "25px", marginTop: "50px" }}
      source="idBookingN"
    />
    <TextInput
      resettable
      label="Nom"
      style={{ marginBottom: "25px", marginTop: "50px" }}
      source="nomClient_contains"
    />
    {/* <TextInput resettable label="Nom du client" style={{ marginBottom: '25px', marginTop: "50px"}} source="nomClient"  /> */}
  </Filter>
);

export const ResasList = (props) => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  });
  return (
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={8}>
        <List
          {...props}
          exporter={false}
          filters={<WantedDate />}
          bulkActionButtons={false}
          sort={{ field: "dateResa", order: "ASC" }}
        >
          <Datagrid rowClick="edit">
            <TextField label="Heure" source="heureResaTxt" />
            <TextField label="Cvts" source="nombreCouverts" />
            <TextField label="Nom" source="nomClient" />
            <DateField label="Date" locales={"fr-FR"} source="dateResa" />
          </Datagrid>
        </List>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Statut de l'API de réservation : <Rings stroke="#98ff98" />
        </span>
      </Grid>
    </Grid>
  );
};

export const ResasCreate = (props) => (
  <>
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={8}>
        <Create title="Ajouter" {...props}>
          <SimpleForm toolbar={<MenuToolbar />} redirect={resas}>
            <TextInput
              fullWidth={true}
              resettable
              label="Nom du client"
              source="nomClient"
            />
            <TextInput
              type="tel"
              fullWidth={true}
              resettable
              label="Numéro"
              source="telClient"
            />
            <TextInput
              fullWidth={true}
              resettable
              label="Couverts"
              source="nombreCouverts"
            />
            <DateInput
              fullWidth={true}
              source="dateResa"
              label="Date"
              options={{ format: "dd/MM/yyyy", ampm: false, clearable: true }}
              providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
            <TextInput
              fullWidth={true}
              type="time"
              source="heureResaTxt"
              label="Heure"
            />
            <TextInput
              type="email"
              fullWidth={true}
              resettable
              label="Email"
              source="emailClient"
            />
            <NumberInput
              fullWidth={true}
              resettable
              label="Table °"
              source="tableClient"
            />
            <TextInput
              fullWidth={true}
              multiline
              label="Demandes"
              source="demandesSpeciales"
            />
          </SimpleForm>
        </Create>
      </Grid>
    </Grid>
  </>
);

export const ResasEdit = (props) => (
  <>
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={8}>
        <Edit title="Modifier" {...props}>
          <SimpleForm toolbar={<MenuToolbar />}>
            <TextInput
              fullWidth={true}
              resettable
              label="Nom du client"
              source="nomClient"
            />
            <TextInput
              fullWidth={true}
              resettable
              label="Numéro"
              source="telClient"
            />
            <TextInput
              fullWidth={true}
              resettable
              label="Couverts"
              source="nombreCouverts"
            />
            <DateInput
              fullWidth={true}
              source="dateResa"
              label="Date"
              options={{ format: "dd/MM/yyyy", ampm: false, clearable: true }}
              providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
            <TextInput
              type="time"
              fullWidth={true}
              source="heureResaTxt"
              label="Heure"
            />
            <TextInput
              type="email"
              fullWidth={true}
              resettable
              label="Email"
              source="emailClient"
            />
            <NumberInput
              fullWidth={true}
              resettable
              label="Table °"
              source="tableClient"
            />
            <TextInput
              fullWidth={true}
              multiline
              label="Demandes"
              source="demandesSpeciales"
            />
            <TextField fullwidth={true} label="ID Résa" source="idBookingN" />
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  </>
);
