import * as React from "react";
import "./App.css";
import {
  fetchUtils,
  Admin,
  Resource,
  Login,
  Layout,
  MenuItemLink,
} from "react-admin";
import simpleRestProvider from "./strapi";
import authProvider from "./authProvider";
import Cookies from "./helpers/Cookies";
import frenchMessages from "./french";
import polyglotI18nProvider from "ra-i18n-polyglot";
import Dashboard from "./Dashboard";
import LoginPage from "./LoginPage";
import { CroquesList, CroquesEdit, CroquesCreate } from "./components/croques";
import {
  TartinesList,
  TartinesEdit,
  TartinesCreate,
} from "./components/tartines";
import { SaladsList, SaladsEdit, SaladsCreate } from "./components/salads";
import {
  StartersList,
  StartersEdit,
  StartersCreate,
} from "./components/starters";
import {
  MaincoursesList,
  MaincoursesEdit,
  MaincoursesCreate,
} from "./components/maincourses";
import { ResasList, ResasCreate, ResasEdit } from "./components/reservations";
import { CheesesList, CheesesEdit, CheesesCreate } from "./components/cheeses";
import { SidesList, SidesEdit } from "./components/sides";
import {
  DessertsList,
  DessertsEdit,
  DessertsCreate,
} from "./components/desserts";
import { TodayList, TodayEdit } from "./components/suggestions";
import { PichetsList, PichetsCreate, PichetsEdit } from "./components/pichets";
import { BottlesList, BottlesCreate, BottlesEdit } from "./components/bottles";
import { WatersList, WatersCreate, WatersEdit } from "./components/waters";
import { BeersList, BeersCreate, BeersEdit } from "./components/beers";
import {
  DraftBeersList,
  DraftBeersCreate,
  DraftBeersEdit,
} from "./components/draftBeers";
import { SoftsList, SoftsCreate, SoftsEdit } from "./components/softs";
import {
  AperitifsList,
  AperitifsCreate,
  AperitifsEdit,
} from "./components/aperitifs";
import {
  DigestifsList,
  DigestifsCreate,
  DigestifsEdit,
} from "./components/digestifs";
import { LunchList, LunchEdit, LunchCreate } from "./components/blockedLunchs";
import { DinerList, DinerEdit, DinerCreate } from "./components/blockedDiners";
import {
  NobookingsList,
  NobookingsEdit,
  NobookingsCreate,
} from "./components/noBookings";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import Battery90Icon from "@material-ui/icons/Battery90";
import TodayIcon from "@material-ui/icons/Today";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import BlockIcon from "@material-ui/icons/Block";
import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import { Truc } from "./Layout";
import TreeMenu from "@bb-tech/ra-treemenu";
import CustomLayout from "./CustomLayout";

const i18nProvider = polyglotI18nProvider(() => frenchMessages, "fr");

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = Cookies.getCookie("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const uploadFields = "image";

const dataProvider = simpleRestProvider(
  "https://api.brasserie-paul.com",
  httpClient,
  uploadFields
);

const myTheme = merge({}, defaultTheme, {
  typography: {
    fontFamily: "Inter",
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiTableRow: {
      root: {
        "&:hover": {
          backgroundColor: "#2196f3",
          color: "#fafafa",
        },
      },
      row: {
        "&:hover": {
          background: "#2196f3",
          color: "#fafafa",
        },
      },
      hover: {
        "&:hover": {
          background: "#2196f3",
          color: "#fafafa",
        },
      },
    },
    RaMenuItemLink: {
      root: {
        borderRadius: "8px",
        padding: "10px",
        marginTop: "5px",
        transition: "all .5s",
        color: "black",
        "&:hover": {
          "& svg": { color: "white" },
          background: "#2196f3",
          color: "#fafafa",
          transition: "all .5s",
        },
      },
      active: {
        "& svg": { color: "white" },
        backgroundColor: "#2196f3",
        color: "#fafafa",
        "&:hover": {
          backgroundColor: "#2196f3",
        },
      },
    },
    RaSidebar: {
      drawerPaper: {
        paddingLeft: "10px",
        paddingRight: "10px",
        backgroundColor: "#eee",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: "lightgray",
        "& > th ": {
          color: "black",
          fontWeight: "bold",
          paddingRight: "0px",
        },
      },
    },

    MuiTableCell: {
      sizeSmall: {
        padding: "6px 2px 6px 16px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    RaCreateButton: {
      floating: {
        bottom: "30px",
      },
    },
    RaTreeMenu: {
      main: {
        marginTop: "0!important",
        fonttSize: "1rem",
      },
    },
    RaTreeCustomMenuItem: {
      openMenuItem: {
        background: "#165991",
        color: "#fafafa",
        "& svg": { color: "white" },
      },
    },
    MuiMenuItem: {
      root: {
        padding: "10px!important",
        borderRadius: "8px",
        "&:hover": {
          "& svg": { color: "white" },
          background: "#165991",
          color: "#fafafa",
          transition: "all .5s",
        },
      },
    },
  },
});

const croques = "croques";
const App = () => (
  <Admin
    loginPage={LoginPage}
    theme={myTheme}
    dashboard={Dashboard}
    title="Gill Côté Bistro"
    authProvider={authProvider}
    dataProvider={dataProvider}
    locale="fr"
    i18nProvider={i18nProvider}
    disableTelemetry
    layout={CustomLayout}
  >
    <Resource
      icon={RestaurantIcon}
      name="suggestions"
      list={TodayList}
      edit={TodayEdit}
      options={{ label: "Formule déjeuner", isMenuParent: false }}
    />
    <Resource
      options={{ label: "Réservations" }}
      icon={TodayIcon}
      name="reservations"
      list={ResasList}
      create={ResasCreate}
      edit={ResasEdit}
      options={{ label: "Réservations", isMenuParent: false }}
    />
    <Resource
      options={{ label: "Midi bloqué", isMenuParent: false }}
      icon={BlockIcon}
      name="blockedlunchs"
      list={LunchList}
      create={LunchCreate}
      edit={LunchEdit}
    />
    <Resource
      options={{ label: "Soir bloqué", isMenuParent: false }}
      icon={BlockIcon}
      name="blockeddiners"
      list={DinerList}
      create={DinerCreate}
      edit={DinerEdit}
    />
    <Resource
      options={{ label: "Journée bloquée", isMenuParent: false }}
      icon={BlockIcon}
      name="nobookings"
      list={NobookingsList}
      create={NobookingsCreate}
      edit={NobookingsEdit}
    />
    <Resource
      name="chaudsdevant"
      options={{ label: "Chauds Devant", isMenuParent: true }}
    />
    <Resource
      icon={RestaurantIcon}
      name="croques"
      list={CroquesList}
      create={CroquesCreate}
      edit={CroquesEdit}
      options={{ label: "Croques", menuParent: "chaudsdevant" }}
    />
    <Resource
      icon={RestaurantIcon}
      name="tartines"
      list={TartinesList}
      create={TartinesCreate}
      edit={TartinesEdit}
      options={{ label: "Tartines", menuParent: "chaudsdevant" }}
    />
    <Resource
      icon={RestaurantIcon}
      name="salads"
      list={SaladsList}
      create={SaladsCreate}
      edit={SaladsEdit}
      options={{ label: "Salades", menuParent: "chaudsdevant" }}
    />
    <Resource
      name="entrees"
      options={{ label: "Entrées", isMenuParent: true }}
    />
    <Resource
      icon={RestaurantIcon}
      name="starters"
      list={StartersList}
      create={StartersCreate}
      edit={StartersEdit}
      options={{ label: "Entrées", menuParent: "entrees" }}
    />
    <Resource name="mains" options={{ label: "Plats", isMenuParent: true }} />
    <Resource
      icon={RestaurantIcon}
      name="maincourses"
      list={MaincoursesList}
      create={MaincoursesCreate}
      edit={MaincoursesEdit}
      options={{ label: "Plats", menuParent: "mains" }}
    />
    <Resource
      icon={RestaurantIcon}
      name="sides"
      list={SidesList}
      edit={SidesEdit}
      options={{ label: "Garnitures", menuParent: "mains" }}
    />
    <Resource
      icon={RestaurantIcon}
      name="cheeses"
      list={CheesesList}
      create={CheesesCreate}
      edit={CheesesEdit}
      options={{ label: "Fromages", menuParent: "mains" }}
    />
    <Resource
      name="pastries"
      options={{ label: "Desserts", isMenuParent: true }}
    />
    <Resource
      icon={RestaurantIcon}
      name="desserts"
      list={DessertsList}
      create={DessertsCreate}
      edit={DessertsEdit}
      options={{ label: "Desserts", menuParent: "pastries" }}
    />
    <Resource name="vins" options={{ label: "Vins", isMenuParent: true }} />
    <Resource
      name="pichets"
      icon={Battery90Icon}
      list={PichetsList}
      create={PichetsCreate}
      edit={PichetsEdit}
      options={{ label: "Pichets", menuParent: "vins" }}
    />
    <Resource name="pichetstypes" />
    <Resource
      name="bottles"
      icon={Battery90Icon}
      list={BottlesList}
      create={BottlesCreate}
      edit={BottlesEdit}
      options={{ label: "Bouteilles", menuParent: "vins" }}
    />
    <Resource name="bottletypes" />
    <Resource
      name="boissons"
      options={{ label: "Les Boissons", isMenuParent: true }}
    />
    <Resource
      name="waters"
      icon={InvertColorsIcon}
      list={WatersList}
      create={WatersCreate}
      edit={WatersEdit}
      options={{ label: "Les Eaux", menuParent: "boissons" }}
    />
    <Resource
      name="draftbeers"
      icon={LocalDrinkIcon}
      list={DraftBeersList}
      create={DraftBeersCreate}
      edit={DraftBeersEdit}
      options={{ label: "Bières pression", menuParent: "boissons" }}
    />
    <Resource
      name="beers"
      icon={LocalDrinkIcon}
      list={BeersList}
      create={BeersCreate}
      edit={BeersEdit}
      options={{ label: "Bières bouteilles", menuParent: "boissons" }}
    />
    <Resource
      name="softs"
      icon={LocalBarIcon}
      list={SoftsList}
      create={SoftsCreate}
      edit={SoftsEdit}
      options={{ label: "Rafraichissements", menuParent: "boissons" }}
    />
    <Resource
      name="aperitifsdigestifs"
      options={{ label: "Apéritifs/Digestifs", isMenuParent: true }}
    />
    <Resource
      name="aperitifs"
      icon={LocalBarIcon}
      list={AperitifsList}
      create={AperitifsCreate}
      edit={AperitifsEdit}
      options={{ label: "Les Apéritifs", menuParent: "aperitifsdigestifs" }}
    />
    <Resource
      name="digestifs"
      icon={LocalCafeIcon}
      list={DigestifsList}
      create={DigestifsCreate}
      edit={DigestifsEdit}
      options={{ label: "Les Digestifs", menuParent: "aperitifsdigestifs" }}
    />
  </Admin>
);

export default App;
