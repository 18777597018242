import React from "react";
import { withServiceWorkerUpdater } from "@3m1/service-worker-updater";
import Grid from "@material-ui/core/Grid";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Button from "@material-ui/core/Button";
import { Rings } from "svg-loaders-react";

const Updater = (props) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  return newServiceWorkerDetected ? (
    <>
      <h3>
        <NotificationImportantIcon style={{ color: "#2196f3" }} /> Nouvelle
        version de l'application détectée ! [{process.env.REACT_APP_VERSION}]
      </h3>

      <p>
        <strong>Fonctionnalités :</strong> suppression automatique des
        réservations enrengistrées à J-1.
      </p>
      <p>
        <strong>Sécurité :</strong> mise à jour des dépendances et correctifs de
        sécurité suite au passage en production.
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={onLoadNewServiceWorkerAccept}
      >
        Mettre à jour
      </Button>
    </>
  ) : (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <div class="success-animation">
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
        </Grid>
        <Grid item> Mises à jour</Grid>
      </Grid>

      <p>
        Vous utilisez la dernière version de l'app, aucune mise à jour n'est
        disponible.
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          Statut de l'API : <Rings stroke="#98ff98" />
        </span>
      </p>
    </>
  ); // If no update is available, render nothing
};

export default withServiceWorkerUpdater(Updater);
