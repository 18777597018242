import * as React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Title, List, TextField, Datagrid, DateField } from "react-admin";
import Updater from "./updater";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import NotesIcon from "@material-ui/icons/Notes";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Hidden from "@material-ui/core/Hidden";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const ResasList = (props) => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  });
  return (
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={8}>
        <List
          actions={null}
          basePath="reservations"
          resource="reservations"
          {...props}
          pagination={false}
          exporter={false}
          hasCreate={false}
          perPage={8}
          bulkActionButtons={false}
          sort={{ field: "created_at", order: "DESC" }}
          title=" "
        >
          <Datagrid rowClick="edit">
            <TextField label="Heure" source="heureResaTxt" />
            <TextField label="Cvts" source="nombreCouverts" />
            <TextField label="Nom" source="nomClient" />
            <DateField label="Date" locales={"fr-FR"} source="dateResa" />
          </Datagrid>
        </List>
      </Grid>
    </Grid>
  );
};

export default () => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  });
  return (
    <>
      <Title title="Brasserie Paul" />
      <Grid
        container
        justify="space-around"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={5}>
          <Accordion style={{ marginTop: "35px", borderRadius: "1em" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <NotesIcon style={{ color: "#2196f3" }} />
                </Grid>
                <Grid item> [{process.env.REACT_APP_VERSION}] Mise à jour</Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="row" alignItems="center">
                <Updater></Updater>
                <Typography>
                  <strong>Fonctionnalités :</strong> ajout de la possibilité
                  d'éditer le tarif de la formule déjeuner. Ce choix se propage
                  automatiquement à travers tout le frontend.
                </Typography>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <h1 className="text-center">Vue rapide</h1>

      <Grid
        container
        justify="space-around"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={10} lg={5}>
          <Accordion style={{ marginTop: "35px", borderRadius: "1em" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <PeopleAltIcon style={{ color: "#2196f3" }} />
                </Grid>
                <Grid item> Dernières réservations reçues</Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="row" alignItems="center">
                <Typography>
                  Retrouvez ici les 8 dernières réservations reçues :
                </Typography>
                <ResasList />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-around"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={10} lg={5}>
          <Card style={{ marginTop: "35px", borderRadius: "1em" }}>
            <CardContent>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <EqualizerIcon style={{ color: "#2196f3" }} />
                </Grid>
                <Grid item> Stats (aujourd'hui)</Grid>
              </Grid>
            </CardContent>
            <CardContent display="flex">
              <Grid container direction="row" alignItems="center">
                <img
                  style={{ paddingTop: "15px" }}
                  src="https://cpemael.avenir-resa.fr/index.php?module=API&method=ImageGraph.get&idSite=3&apiModule=UserCountry&apiAction=getCountry&token_auth=9f60110a78789c9fc47ef1e7104f3710&graphType=horizontalBar&period=day&date=today&width=320&height=380&colors=2196f3,2196f3,2196f3,2196f3,2196f3,2196f3"
                  alt="Résumé des visites par pays"
                />
                <Hidden only={["md", "lg", "xl"]}>
                  <hr />
                </Hidden>
                <img
                  src="https://cpemael.avenir-resa.fr/index.php?module=API&method=ImageGraph.get&idSite=3&apiModule=VisitTime&apiAction=getVisitInformationPerLocalTime&token_auth=9f60110a78789c9fc47ef1e7104f3710&graphType=horizontalBar&period=day&date=today&width=320&height=380&colors=2196f3,2196f3,2196f3,2196f3,2196f3,2196f3"
                  alt="Résumé des visites par heure locale"
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10} lg={5}>
          <Card style={{ marginTop: "35px", borderRadius: "1em" }}>
            <CardContent>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <EqualizerIcon style={{ color: "#2196f3" }} />
                </Grid>
                <Grid item> Stats (7 derniers jours)</Grid>
              </Grid>
            </CardContent>
            <CardContent display="flex">
              <img
                style={{ paddingTop: "15px" }}
                src="https://cpemael.avenir-resa.fr/index.php?module=API&method=ImageGraph.get&idSite=3&apiModule=VisitsSummary&apiAction=get&token_auth=9f60110a78789c9fc47ef1e7104f3710&graphType=horizontalBar&period=day&date=previous7&width=320&height=380&colors=2196f3,2196f3,2196f3,2196f3,2196f3,2196f3"
                alt="Résumé des visites sur 7 jours"
              />
              <Hidden only={["md", "lg", "xl"]}>
                <hr />
              </Hidden>
              <img
                src="https://cpemael.avenir-resa.fr/index.php?module=API&method=ImageGraph.get&idSite=3&apiModule=Goals&apiAction=get&token_auth=9f60110a78789c9fc47ef1e7104f3710&graphType=horizontalBar&period=day&date=last7&width=320&height=380&colors=2196f3,2196f3,2196f3,2196f3,2196f3,2196f3"
                alt="Résumé des scans de QR Code sur les 7 derniers jours"
              />
              <p>
                Légende : <em>Conversions = scans de QR Code</em>
              </p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-evenly"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={10} lg={5}>
          <Accordion
            style={{
              marginTop: "35px",
              marginBottom: "42px",
              borderRadius: "1em",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <EqualizerIcon style={{ color: "#2196f3" }} />
                </Grid>
                <Grid item> Stats (30 derniers jours)</Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <img
                style={{ paddingTop: "15px" }}
                src="https://cpemael.avenir-resa.fr/index.php?module=API&method=ImageGraph.get&idSite=3&apiModule=VisitsSummary&apiAction=get&token_auth=9f60110a78789c9fc47ef1e7104f3710&graphType=horizontalBar&period=day&date=previous30&width=320&height=580&colors=2196f3,2196f3,2196f3,2196f3,2196f3,2196f3"
                alt="Résumé des visites sur 30 jours"
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};
