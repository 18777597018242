import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  Filter,
  regex,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import BackButton from "../backButton";
const validatePrix = regex(
  /^[0-9 ,]*$/,
  "Seulement chiffres et virgule, le symbole € est automatique rajouté"
);

const waters = () => `/waters`;

const MenuToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton
      variant="outlined"
      color="secondary"
      style={{ marginLeft: "1rem" }}
    >
      Annuler
    </BackButton>
    <DeleteButton />
  </Toolbar>
);

export const WatersList = (props) => {
  return (
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          perPage={10}
        >
          <Datagrid rowClick="edit">
            <TextField label="Intitulé" source="title" />
          </Datagrid>
        </List>
      </Grid>
    </Grid>
  );
};

export const WatersCreate = (props) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={5}>
          <Create title="Ajouter" {...props}>
            <SimpleForm toolbar={<MenuToolbar />} redirect={waters}>
            <TextInput
              fullWidth
              resettable
              multiline
              label="Intitulé"
              source="title"
            />
            <TextInput label="Prix 50Cl" source="small" />
            <TextInput label="Prix 75Cl" source="large" />
            </SimpleForm>
          </Create>
        </Grid>
      </Grid>
    </>
  );
};

export const WatersEdit = (props) => {
  return (
    <Grid container justifyContent="center" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <Edit title="Modifier" {...props}>
          <SimpleForm toolbar={<MenuToolbar />}>
            <TextInput
              fullWidth
              resettable
              multiline
              label="Intitulé"
              source="title"
            />
            <TextInput label="Prix 50Cl" source="small" />
            <TextInput label="Prix 75Cl" source="large" />
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  );
};
