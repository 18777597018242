import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  Filter,
  BooleanInput,
  regex,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import BackButton from "../backButton";
const validatePrix = regex(
  /^[0-9 ,]*$/,
  "Seulement chiffres et virgule, le symbole € est automatique rajouté"
);

const cheeses = () => `/cheeses`;

const MenuToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton
      variant="outlined"
      color="secondary"
      style={{ marginLeft: "1rem" }}
    >
      Annuler
    </BackButton>
    <DeleteButton />
  </Toolbar>
);

const WantedInfo = (props) => (
  <Filter {...props}>
    <BooleanInput
      resettable
      label="Gite"
      style={{ marginBottom: "25px", marginTop: "50px" }}
      source="gite"
    />
  </Filter>
);

export const CheesesList = (props) => {
  return (
    <Grid container justify="space-around" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <List {...props} exporter={false} bulkActionButtons={false} perPage={10}>
          <Datagrid rowClick="edit">
            <TextField label="Intitulé" source="title_fr" />
            <TextField label="Prix €" source="price" validate={validatePrix} />
          </Datagrid>
        </List>
      </Grid>
    </Grid>
  );
};

export const CheesesCreate = (props) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={5}>
          <Create title="Ajouter" {...props}>
            <SimpleForm toolbar={<MenuToolbar />} redirect={cheeses}>
              <TextInput
                fullWidth={true}
                resettable
                label="Intitulé FR"
                source="title_fr"
              />
              <TextInput
                fullWidth={true}
                resettable
                label="Intitulé EN"
                source="title_en"
              />
              <TextInput
                fullWidth
                resettable
                multiline
                label="Description FR"
                source="description_fr"
              />
              <TextInput
                fullWidth
                resettable
                multiline
                label="Description EN"
                source="description_en"
              />
              <TextInput source="price" label="Prix" validate={validatePrix} />
            </SimpleForm>
          </Create>
        </Grid>
      </Grid>
    </>
  );
};

export const CheesesEdit = (props) => {
  return (
    <Grid container justifyContent="center" direction="row" alignItems="center">
      <Grid item xs={12} md={5} lg={5}>
        <Edit title="Modifier" {...props}>
          <SimpleForm toolbar={<MenuToolbar />}>
            <TextInput
              fullWidth={true}
              resettable
              multiline
              label="Intitulé FR"
              source="title_fr"
            />
            <TextInput
              fullWidth={true}
              resettable
              multiline
              label="Intitulé EN"
              source="title_en"
            />
            <TextInput
              fullWidth
              resettable
              multiline
              label="Description FR"
              source="description_fr"
            />
            <TextInput
              fullWidth
              resettable
              multiline
              label="Description EN"
              source="description_en"
            />
            <TextInput source="price" label="Prix" validate={validatePrix} />
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  );
};
