export default function Calendar() {
  return(
    <>
   <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 40 46"
      xmlSpace="preserve"
      id="calendar"
    >
      <g transform="translate(-12 -8)">
        <g className="scale-in-center">
          <g className="st1" opacity="0.2">
            <path
              fill="#231f20"
              d="M12 25v25c0 2.2 1.8 4 4 4h32c2.2 0 4-1.8 4-4V25z"
              className="st2"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M12 23v25c0 2.2 1.8 4 4 4h32c2.2 0 4-1.8 4-4V23z"
            ></path>
          </g>
          <g className="st1" opacity="0.2">
            <path
              fill="#231f20"
              d="M48 14H16c-2.2 0-4 1.8-4 4v7h40v-7c0-2.2-1.8-4-4-4z"
              className="st2"
            ></path>
          </g>
          <g>
            <path
              fill="#2196f3"
              fillOpacity="1"
              d="M48 12H16c-2.2 0-4 1.8-4 4v7h40v-7c0-2.2-1.8-4-4-4z"
            ></path>
          </g>
          <g className="st1" opacity="0.2">
            <path
              fill="#231f20"
              d="M20 21c-1.1 0-2-.9-2-2v-7c0-1.1.9-2 2-2s2 .9 2 2v7c0 1.1-.9 2-2 2z"
              className="st2"
            ></path>
          </g>
          <g className="st1" opacity="0.2">
            <path
              fill="#231f20"
              d="M45 21c-1.1 0-2-.9-2-2v-7c0-1.1.9-2 2-2s2 .9 2 2v7c0 1.1-.9 2-2 2z"
              className="st2"
            ></path>
          </g>
          <g>
            <path
              fill="#e0e0d1"
              d="M20 19c-1.1 0-2-.9-2-2v-7c0-1.1.9-2 2-2s2 .9 2 2v7c0 1.1-.9 2-2 2z"
              className="st6"
            ></path>
          </g>
          <g>
            <path
              fill="#e0e0d1"
              d="M45 19c-1.1 0-2-.9-2-2v-7c0-1.1.9-2 2-2s2 .9 2 2v7c0 1.1-.9 2-2 2z"
              className="st6"
            ></path>
          </g>
        </g>
      </g>
      <rect
        width="4.339"
        height="3.525"
        x="7.836"
        y="32.749"
        fill="#2196f3"
        fillOpacity="0.988"
        fillRule="nonzero"
        stroke="#3f7136"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="0.995"
        strokeWidth="0"
        opacity="1"
        ry="0.935"
        className="a"
      ></rect>
      <rect
        width="4.339"
        height="3.525"
        x="17.83"
        y="32.665"
        fill="#2196f3"
        fillOpacity="0.988"
        fillRule="nonzero"
        stroke="#3f7136"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="0.995"
        strokeWidth="0"
        opacity="1"
        ry="0.935"
        className="a"
      ></rect>
      <rect
        width="4.339"
        height="3.525"
        x="27.586"
        y="32.808"
        fill="#2196f3"
        fillOpacity="0.988"
        fillRule="nonzero"
        stroke="#3f7136"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="0.995"
        strokeWidth="0"
        opacity="1"
        ry="0.935"
        className="b"
      ></rect>
      <rect
        width="4.339"
        height="3.525"
        x="27.538"
        y="23.149"
        fill="#2196f3"
        fillOpacity="0.988"
        fillRule="nonzero"
        stroke="#3f7136"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="0.995"
        strokeWidth="0"
        opacity="1"
        ry="0.935"
        className="b"
      ></rect>
      <rect
        width="4.339"
        height="3.525"
        x="17.998"
        y="23.244"
        fill="#2196f3"
        fillOpacity="0.988"
        fillRule="nonzero"
        stroke="#3f7136"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="0.995"
        strokeWidth="0"
        opacity="1"
        ry="0.935"
        className="c"
      ></rect>
      <rect
        width="4.339"
        height="3.525"
        x="7.955"
        y="23.34"
        fill="#2196f3"
        fillOpacity="0.988"
        fillRule="nonzero"
        stroke="#3f7136"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="0.995"
        strokeWidth="0"
        opacity="1"
        ry="0.935"
        className="c"
      ></rect>
    </svg>
    </>                        
  )
}